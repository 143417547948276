import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { UserProvider } from "../../../../Providers";
import types from "../VerificationUserButton/types";
import Swal from "sweetalert2";
import { CircularProgress } from '@material-ui/core';

import useAccessRole from "../../../../hooks/useAccessRole";
import { useAlerts, useReset } from "../../../../hooks";

const UserButtons = (props) => {
  const {
    history,
    hasEdit,
    record: {
      id,
      attributes: {
        first_name,
        last_name,
        profile_verification,
        profile_completed,
        status,
        biometric_verification,
        biometric_url,
      },
    },
  } = props;
  const { resetList } = useReset();
  const { isAccess } = useAccessRole();
  const { showModal, confirmationModal } = useAlerts();
  const [isRequestingBiometric, setIsRequestingBiometric] = useState(false);

  const goToShowUser = () => {
    history.push(`/usuarios/${id}/show`);
  };

  const goToEditUser = () => {
    history.push(`/usuarios/${id}`);
  };

  const acceptVerification = async () => {
    try {
      await UserProvider.acceptedUsersVerification(id);
      resetList();
    } catch (e) {}
  };

  const rejectedVerification = async () => {
    Swal.fire({
      text: "Ingrese el motivo del rechazo",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
        maxlength: 200,
        required: true,
      },
      inputAutoTrim: true,
      validationMessage: "Ingrese un motivo de rechazo",
      showCancelButton: true,
      confirmButtonText: "Siguiente",
      cancelButtonText: "Cancelar",
      showLoaderOnConfirm: true,
    }).then((description_result) => {
      if (!description_result.value){
        Swal.fire({
          title: 'Error',
          text: 'No se ingreso un motivo de rechazo',
          icon: 'error',
          confirmButtonText: 'OK'
        });
        return;
      }
      if (description_result.isConfirmed) {
        showModal({
          text: "¿Quieres rechazar el usuario?",
          confirmButtonText: "Sí, Rechazar!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              await UserProvider.rejectedUsersVerification(id, description_result.value);
              resetList();
            } catch (e) {
              showModal({
                title: '',
                text: e.data.message,
                showCancelButton: false,
                confirmButtonText: 'OK'
              });
            }
          }
        });
      }
    }).catch(() => {
      Swal.fire({
        title: 'Error',
        text: 'Error en actualización del perfil',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    });
  };

  const resetVerification = async () => {
    const response = await showModal({
      text: "¿Quieres reiniciar toda la información del usuario?",
      confirmButtonText: "Sí, Reiniciar!",
    })
    if (response.isConfirmed) {
      try {
        await UserProvider.resetUsersVerification(id);
        resetList();

          confirmationModal({
            text: "La información del usuario ha sido reiniciada",
          })
        } catch (e) {}
      }
  };

  const requestBiometricVerification = async () => {
    const response = await showModal({
      text: "¿Quieres solicitar un proceso de biometría del usuario?",
      confirmButtonText: "Sí, Solicitar!",
    })
    if (response.isConfirmed) {
        try {
          setIsRequestingBiometric(true);
          await UserProvider.requestBiometricUsersVerification(id);
          setIsRequestingBiometric(true);
          resetList();
        } catch (error) {
          setIsRequestingBiometric(false);
          Swal.fire({
            title: 'No fue posible iniciar el proceso de biometría',
            text: `Detalle: ${JSON.stringify(error.data)}`,
            icon: 'error',
          });
        }
      }
  };

  const validateProfile = () => (profile_verification === "verification_required") ? true : false;

  const updateProfile = async () => {
    let confirmation = await Swal.fire({
      text: "Ingrese el motivo de la solicitud (Visible para el usuario)",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
        required: 'true'
      },
      showCancelButton: true,
      confirmButtonText: "Siguiente",
      cancelButtonText: "Cancelar",
      showLoaderOnConfirm: true,
    });

    if (confirmation.isConfirmed) {
      const reasonToChange = confirmation.value;

      confirmation = await Swal.fire({
        text: "¿Quieres continuar la solicitud de actualización?",
        confirmButtonText: "Sí, Solicitar actualización",
        showCancelButton: true,
        cancelButtonText: "No, cancelar",
        showLoaderOnConfirm: true,
      });
        
      if (confirmation.isConfirmed) {
        try {
          const result = await UserProvider.requestUpdateProfile(id, { reason_to_change: reasonToChange });
          if (result.data.message) {
            Swal.fire({
              title: 'Muy bien!',
              icon: "success",
              background: "rgb(19,19,19)",
              text: result.data.message
            });

            resetList();
          } else {
            throw new Error('No message in response was received');
          }
        } catch (error) { 
          Swal.fire('Error en actualización del perfil');
        }
      }
    };
  };

  const renderUpdateProfileButton = () => (
    <div style={{ padding: 10, width: 100 }}>
      <Button
        variant="contained"
        color="primary"
        style={{ backgroundColor: validateProfile() ? "gray" : "#00b7ff", marginRight: '20%' }}
        onClick={updateProfile}
        disabled={validateProfile()}
      >
        Pedir actualización
      </Button>
    </div>
  )

  const unbannedUser = async () => {
    try {
      await UserProvider.unbannedUser(id);
      resetList();
    } catch (e) {}
  };

  const bannedUser = async () => {
    try {
      await UserProvider.bannedUser(id);
      resetList();
    } catch (e) {}
  };

  const renderProfileButtons = () => {
    if (status === "profile_review") {
      return null;
    }

    if (profile_completed)
      switch (profile_verification) {
        case types.REJECT_VERIFICATION: {
          return isAccess("verify_accounts") ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ padding: 10, width: 100 }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ backgroundColor: "#00b7ff" }}
                  onClick={acceptVerification}
                >
                  Aprobar verificación
                </Button>
              </div>
            </div>
          ) : null;
        }
        case types.ACCEPTED_VERIFICATION: {
          return isAccess("verify_accounts") && (
            <>
              <div style={{ padding: 10, width: 100 }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ backgroundColor: "#ff0033" }}
                  onClick={rejectedVerification}
                >
                  Rechazar verificación
                </Button>
              </div>
            </>
          )
        }
        default: {
          return isAccess("verify_accounts") && (
            <div style={{ display: "flex", flexDirection: "column" }}>
                {
                  profile_verification !== "verification_required" &&
                  (
                    <>
                      <div style={{ padding: 10, width: 100 }}>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ backgroundColor: "#00b7ff" }}
                          onClick={acceptVerification}
                        >
                          Aprobar verificación
                        </Button>
                      </div>
                      <div style={{ padding: 10, width: 100 }}>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ backgroundColor: "#ff0033" }}
                          onClick={rejectedVerification}
                        >
                          Rechazar verificación
                        </Button>
                      </div>
                      { (
                        (biometric_verification === 'biometric_none' || biometric_verification === 'biometric_failed') &&
                        isAccess('request_biometric_verification')
                        ) && (
                        <div style={{ padding: 10, width: 100 }}>
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ backgroundColor: "#55c18a" }}
                            onClick={requestBiometricVerification}
                            disabled={biometric_verification === 'biometric_pending'}
                          >
                            {(isRequestingBiometric) && <CircularProgress size={20} color={'secondary'}/>}
                            {(!isRequestingBiometric) && (biometric_verification === 'biometric_pending' ? 'Biometría pendiente' : 'Solicitar biometría')}
                          </Button>
                        </div>
                      )}
                    </>
                  )
                }
            </div>
          )
        }
      }
  };

  const resetVerificationButton = () => {
    return (
      <div style={{ padding: 10, width: 100 }}>
        <Button
          variant="contained"
          color="primary"
          style={{ backgroundColor: "#f0c400" }}
          onClick={resetVerification}
        >
          Resetear verificación
        </Button>
      </div>
    )
  } 

  const banUser = () => {
    return (
      <div style={{ padding: 10, width: 100 }}>
        <Button
          variant="contained"
          color="primary"
          style={{
            backgroundColor: status === "banned" ? "#00b7ff" : "#ff0033",
          }}
          onClick={status === "banned" ? unbannedUser : bannedUser}
        >
          {status === "banned" ? "Desbanear usuario" : "Banear usuario"}
        </Button>
      </div>
    );
  };

  const showBiometric = () => {
    return (
      <div style={{ padding: 10, width: 100 }}>
        <Button
          variant="contained"
          color="primary"
          style={{ backgroundColor: "#00b7ff" }}
          onClick={()=> window.open(biometric_url, "_blank")}
        >
          Ver biometría
        </Button>
      </div>
    );
  }

  const resetSession = () => {
    showModal({
      text: "¿Quieres resetear la sesión del usuario?",
      confirmButtonText: "Sí, Resetear!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const result = await UserProvider.resetUserSession(id);

          if(result.data.message){

            confirmationModal({
              text: result.data.message,
            })
          }else{
            var message =  result.data.errors[0] + "\n" + result.data.errors[0];
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              html: result.data.errors.join('.<br/>')
            })
          }
        } catch (e) {}
      }
    });
  };

  const resetSessionButton = () => {
    return (
      <div style={{ padding: 10, width: 100 }}>
        <Button
          variant="contained"
          color="primary"
          style={{
            backgroundColor: "#00F5A5",
          }}
          onClick={resetSession}
        >
          Resetear sesión
        </Button>
      </div>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingRight: 30,
      }}
    >
      {isAccess("delete_users") || isAccess("unbaned_users") ? banUser() : null}
      {isAccess("reset_session") ? resetSessionButton() : null}
      { biometric_url && showBiometric() }
      {renderProfileButtons()}
      { isAccess('reset_user_verification') && profile_verification!== 'initial' && resetVerificationButton()}
      {profile_completed &&  isAccess('request_update_profile') ? renderUpdateProfileButton() : null}
      {profile_completed && (
        <div style={{ padding: 10, width: 100 }}>
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: "#00b7ff" }}
            onClick={goToShowUser}
          >
            Ver información
          </Button>
        </div>
      )}
      {hasEdit && profile_verification !== "initial" ? (
        <div style={{ padding: 10, width: 100 }}>
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: "#131313" }}
            onClick={goToEditUser}
          >
            Editar información
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default UserButtons;
