import React from 'react';
import { Filter, TextInput, DateInput } from 'react-admin';

import { Grid, withStyles } from '@material-ui/core';

import ButtonClearFilters from '../../../../Components/ButtonClearFilters';
import useAccessRole from '../../../../hooks/useAccessRole';

const styles = {
  helperText: {
    color: ' rgba(255, 255, 255, 0.7)',
    margin: '8px 0',
    fontSize: '0.75rem',
    textAlign: 'left',
    lineHeight: '1em'
  },
  dateInput: {
    margin: '8px 0 0 0',
    '&:nth-child(1)': {
      paddingRight: '5px'
    },
    '&:nth-child(2)': {
      paddingLeft: '5px'
    },
    '& input[type="date"]::-webkit-calendar-picker-indicator': {
      backgroundColor: ' rgb(140, 140, 140)',
      borderRadius: '5px',
      display: 'block'
    }
  }
};

const ExportedFilter = (props) => {
  const { classes, setFilters } = props;
  const {isAccess} = useAccessRole();

  return (
    <Filter {...props}>
      <ButtonClearFilters setFilters={setFilters} alwaysOn/>
      {isAccess('show_export_files') &&
        <TextInput
          label={'Buscar'}
          source={'search_key'}
          helperText={"Busca un admin por email"}
          alwaysOn
        />
      }
      <Grid
        alwaysOn
        label="Fecha de solicitud de archivo"
        source={'history_dates'}
      >
        <Grid
          container
        >
          <DateInput
            label={'Desde'}
            source="export_dates.export_date_start"
            className={classes.dateInput}
          />
          <DateInput
            label={'Hasta'}
            source="export_dates.export_date_end"
            className={classes.dateInput}
          />
        </Grid>
        <p className={classes.helperText}>Fecha de solicitud de archivo</p>
      </Grid>
    </Filter>
  );
};

export default withStyles(styles)(ExportedFilter);
