import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { Confirm, crudDeleteMany } from 'react-admin';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';

class BulkDeleteButton extends Component {
  state = {
    isOpen: false,
  }

  handleClick = () => {
    this.setState({ isOpen: true });
  }

  handleDialogClose = () => {
    this.setState({ isOpen: false });
  };

  handleConfirm = () => {
    const { basePath, crudDeleteMany, resource, selectedIds } = this.props;
    crudDeleteMany(resource, selectedIds, { views: 0 }, basePath);
    this.setState({ isOpen: true });
  };

  render() {
    return (
      <Fragment>
        <Button onClick={this.handleClick} >
          <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
            <DeleteIcon />
            <span>Delete</span>
          </div>
        </Button>
        <Confirm
          isOpen={this.state.isOpen}
          title="Eliminar movimientos bancarios"
          content="¿Estas seguro que quieres eliminar los movimientos bancarios seleccionados?"
          onConfirm={this.handleConfirm}
          onClose={this.handleDialogClose}
        />
      </Fragment>
    );
  }
}

export default connect(undefined, { crudDeleteMany })(BulkDeleteButton);