import React                            from 'react';
import { Filter, TextInput } from 'react-admin';
import { withStyles }             from '@material-ui/core';

import ButtonClearFilters from '../../../../Components/ButtonClearFilters';

const styles = {
  helperText: {
    color:' rgba(255, 255, 255, 0.7)',
    margin: '8px 0',
    fontSize: '0.75rem',
    textAlign: 'left',
    lineHeight: '1em'
  },
  dateInput: {
    margin: '8px 0 0 0',
    '&:nth-child(1)' : {
      paddingRight: '5px'
    },
    '&:nth-child(2)' : {
      paddingLeft: '5px'
    },
    '& input[type="date"]::-webkit-calendar-picker-indicator': {
      backgroundColor:' rgb(140, 140, 140)',
      borderRadius: '5px',
      display: 'block'
    }
  }
};

const CardAccountFilter = (props) => {
  return (
    <Filter {...props}>
      <ButtonClearFilters setFilters={props.setFilters} alwaysOn/>
      <TextInput
        label={'ID'}
        source={'id'}
        helperText={"Busca un beneficiario por id"}
        alwaysOn
      />
      <TextInput
        label={'Buscar'}
        source={'search_key'}
        helperText={"Busca un beneficiario por nombre, apellido, DNI o email."}
        alwaysOn
      />
    </Filter>
  );
};

export default withStyles(styles)(CardAccountFilter);
